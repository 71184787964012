import Divider from 'components/Divider';
import section from 'assets/images/section-platforms.png';

export default function SectionPlatform() {
  return (
    <section>
      <div className="content">
        <div className="flex flex-col items-end justify-center lg:pl-[116px]">
          <div className="w-full lg:w-[350px] xl:w-full">
            <h2>Re-distribución en múltiples plataformas</h2>
            <Divider />
            <p>Trasmite en varias plataformas simultáneamente, ahorrando ancho de banda gracias a nuestros servidores dedicados</p>
          </div>
        </div>
        <div className="order-first lg:order-last">
          <img src={section} alt="" className="w-full xl:w-full lg:w-[350px]" />
        </div>
      </div>
    </section>
  );
}