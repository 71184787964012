import Header from 'components/Header';
import SectionExplorer from 'components/SectionExplorer';
import SectionPlatform from 'components/SectionPlatforms';
import SectionRecording from 'components/SectionRecording';
import SectionPrivacy from 'components/SectionPrivacy';

export default function Home() {
  return (
    <>
      <Header />
      <SectionExplorer />
      <SectionPlatform />
      <SectionRecording />
      <SectionPrivacy />
    </>
  );
}