export default function SocialLink({ href, src, hoverSrc, alt }) {
  const handleMouseOver = (e) => {
    if (hoverSrc)
      e.currentTarget.src = hoverSrc;
  };

  const handleMouseOut = (e) => {
    e.currentTarget.src = src;
  };

  return (
    <a href={href} target="_blank" className="p-0.5">
      <img
        src={src}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        alt={alt} />
    </a>
  );
}