import { useContext } from 'react';
import { ThemeContext } from 'configs/context';
import Button from 'components/Button';
import hero from 'assets/images/hero.png';
import heroParallax from 'assets/images/parallax-screen.png';

import { useInView, useScroll, motion, useTransform, inView } from 'framer-motion';

export default function Header() {
  const { scrollY } = useScroll();
  const y1 = useTransform(scrollY, [0, 300], [0, -100]);
  const y2 = useTransform(scrollY, [0, 300], [0, 100]);
  
  const { updateState } = useContext(ThemeContext);

  const handleModal = () => {
    updateState({ modal: true });
  }

  return (
    <header className="flex flex-col items-center gap-6 pt-8 pb-12 lg:pt-20 px-6 lg:px-0">
      <h1>Transmite en vivo y comparte</h1>
      <p className="text-center lg:max-w-[700px]">Crea contenido en vivo y compártelo en tus plataformas favoritas al instante o guárdalo para usarlo en otro momento</p>
      <Button
        className="!w-fit"
        text="Comenzá a crear contenido gratis"
        onClick={handleModal} />
      {/* <img src={hero} alt="" className="lg:w-[721px] xl:w-[1265px] w-auto" /> */}
      <div className="relative lg:w-[721px] xl:w-[1265px] w-auto">
        <div className="absolute w-full h-full -z-10">
          <motion.div
            animate={inView ? 'visible' : 'hidden'}
            className="circle"
            style={{ y: y1, x: -80 }} />
          <motion.div
            className="box"
            style={{ y: y2, x: '100%' }}
          />
        </div>
        <div className='bg-[#fff5f8] rounded-xl p-2 lg:p-5'>
          <img src={heroParallax} alt="" className="lg:w-[721px] xl:w-[1265px] w-auto" />
        </div>
      </div>
    </header>
  );
}