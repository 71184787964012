import Divider from 'components/Divider';
import section from 'assets/images/section-privacy.png';

export default function SectionPrivacy() {
  return (
    <section>
      <div className="content">
        <div className="flex flex-col items-end justify-center xl:pl-[116px]">
          <div className="w-full lg:w-[350px] xl:w-full">
            <h2>Transmisiones privadas</h2>
            <Divider />
            <p>Crea transmisiones en vivo privadas con chat para insertar en tu plataforma</p>
          </div>
        </div>
        <div className="order-first lg:order-last">
          <img src={section} alt="" className="w-full xl:w-full lg:w-[350px]" />
        </div>
      </div>
    </section>
  );
}