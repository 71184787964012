import Divider from 'components/Divider';
import section from 'assets/images/section-explorer.png';

export default function SectionExplorer() {
  return (
    <section className="bg-pkst-gray">
      <div className="content">
        <div className="flex flex-col items-end">
          <img src={section} alt="" className="w-full lg:w-[350px] xl:w-full" />
        </div>
        <div className="flex flex-col justify-center">
          <div className="w-full lg:w-[350px] xl:w-full">
            <h2>Todo en tu navegador</h2>
            <Divider />
            <p>Sin descargas innecesarias, comenzá transmitir en cuestión de minutos directamente desde tu navegador</p>
          </div>
        </div>
      </div>
    </section>
  );
}