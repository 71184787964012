import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import NavLinks from 'components/NavLink';
import Hamburguer from 'components/Hamburguer';
import logo from 'assets/images/logo.svg';

export default function Nav() {
  const { navigation } = require('../configs/global');
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  }

  return (
    <>
      <nav>
        <div className="menu">
          <div className="grow">
            <div className="max-w-fit">
              <Link to="/">
                <img src={logo} alt="pkst studio" />
              </Link>
            </div>
          </div>
          <div>
            <button type="button" onClick={handleOpen} className="p-1 lg:hidden">
              <Hamburguer active={isOpen} />
            </button>
            <div className="hidden lg:block">
              <NavLinks items={navigation} />
            </div>
          </div>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="absolute left-0 top-[92px] bg-white w-full h-full px-6 py-3">
              <NavLinks items={navigation} onClick={handleOpen} />
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </>
  );
}
