import { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

export default function Divider() {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref);

  const variants = {
    visible: { opacity: 1, width: 100, transition: { delay: .2, duration: .5, ease: 'easeOut' } },
    hidden: { opacity: 0, width: 0 }
  }

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      variants={variants}
      initial="hidden"
      className="divider" />
  );
}