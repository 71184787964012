import { AnimatePresence, motion } from 'framer-motion';

export default function Modal({ children, show }) {
  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 }
  }

  const containerVariants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: 100 }
  }

  return (
    <>
      <motion.div
        animate={show ? 'open' : 'closed'}
        variants={variants}
        className={`z-10 ${show ? 'relative' : 'hidden'}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true">
        <div className="fixed inset-0 bg-pkst-black bg-opacity-80 transition-opacity" aria-hidden={show ? 'true' : 'false'}></div>
        <motion.div
          animate={show ? 'open' : 'closed'}
          variants={containerVariants}
          className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white p-8">
                {children}
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
}