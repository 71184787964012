import { useTranslation } from 'react-i18next';
import SocialLink from 'components/SocialLink';
import linkedin from 'assets/images/linkedin.svg';
import github from 'assets/images/github.svg';

export default function Footer() {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="container grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-y-0">
        <div className="flex items-center justify-center lg:justify-normal">
          <a href="/pkst-studio-terms-conditions.pdf">{t('footer.terms')}</a>
        </div>
        <div className="flex items-center justify-center order-3 lg:order-2">
          © {year} Peekast Media LLC
        </div>
        <div className="flex items-center justify-center lg:justify-end order-2 lg:order-3">
          <SocialLink
            href="https://www.linkedin.com/company/peekastok/"
            src={linkedin}
            alt="LinkedIn" />
          <SocialLink
            href="https://github.com/Peekast"
            src={github}
            alt="GitHub" />
        </div>
      </div>
    </footer>
  );
}
