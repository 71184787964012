import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguangeDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguangeDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    debug: true,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0
    },
    interpolation: {
      escapeValue: true
    }
  });

export default i18n;