import { useEffect, useState } from 'react';
import iconSpinner from 'assets/images/icon-spinner.svg';

export default function Button({
  type = 'button',
  text,
  loading,
  className,
  onClick
}) {
  const [formatedText, setFormatedText] = useState('');

  useEffect(() => {
    const firstLetter = text.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remaningLetters = text.slice(1);

    setFormatedText(firstLetterCap + remaningLetters);
  }, [text]);

  return (
    <button type={type} className={className + ' btn'} onClick={onClick}>
      {loading && (
        <img src={iconSpinner} alt="" width={20} height={20} className="absolute" />
      )}
      <div className={`${loading ? 'invisible' : ''}`}>{formatedText}</div>
    </button>
  );
}