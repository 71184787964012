import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'configs/context';
import Button from 'components/Button';

export default function NavLinks({ items, onClick }) {
  const { t } = useTranslation();
  const { updateState } = useContext(ThemeContext);

  const handleModal = () => {
    updateState({ modal: true });
  }

  const handleButtonClick = () => {
    handleModal();
    if (onClick) {
      onClick();
    }
  }

  return (
    <ul className="nav">
      {items.map(item =>
        <li key={item.text}>{item.call_to_action
          ? (<Button text={t(`menu.${item.text}`)} onClick={handleButtonClick}  />)
          : (<NavLink to={item.link} target={item.external ? '_blank' : '_self'} onClick={onClick}>{t(`menu.${item.text}`)}</NavLink>)}
        </li>)
      }
    </ul>
  );
}