import { useContext } from 'react';
import { ThemeContext } from 'configs/context';
import Button from 'components/Button';

export default function Prices() {
  const { updateState } = useContext(ThemeContext);

  const handleModal = () => {
    updateState({ modal: true });
  }

  return (
    <>
      <div className="container flex flex-col items-center pb-20">
        <header className="flex flex-col items-center gap-6 pt-8 pb-12 lg:pt-20 px-6 lg:px-0">
          <h1>Comienza a stremear hoy mismo</h1>
          <p className="text-center lg:max-w-[700px]">
            Elige el plan que mejor se adapte a tus necesidades y comienza a stremear en múltiples plataformas hoy mismo.
          </p>
          <p className="text-sm">* Los precios de los planes son en dólares.</p>
        </header>
        <div className="grid grid-cols-1 lg:grid-cols-3 items-end gap-8 max-w-7xl w-full px-8">
          <div className="grow-0 flex-none border border-pkst-dark-gray rounded-lg border-t-8">
            <div className="flex flex-col gap-4 border-b p-6">
              <h2 className="text-2xl">Gratuito</h2>
              <p className="text-base">
                Descubre nuestro <b className="font-matter-bold">Plan Gratuito</b>, perfecto para aquellos que recién comienzan.
              </p>
              <div className="flex items-center gap-2">
                <div className="font-mark-bold text-5xl text-pkst-rose">$0</div>
                <div className="text-sm text-pkst-black">/ para siempre</div>
              </div>
            </div>
            <div className="py-8 px-6">
              <p className="font-matter-bold text-base mb-1">Funcionalidades incluidas:</p>
              <ul className="flex flex-col gap-1 mb-6">
                <li>· Agregar 1 invitado</li>
                <li>· Compartir pantalla</li>
                <li>· Compartir video</li>
                <li>· Banner y ticker</li>
                <li>· Cambio de layouts</li>
                <li>· Sin marca de agua</li>
                <li>· 15 horas de transmisión en total</li>
                <li>· 1 sólo destino</li>
              </ul>
              <Button text="Empezar gratis" className="secondary" onClick={handleModal} />
            </div>
          </div>
          <div className="border border-pkst-pink rounded-lg border-t-8">
            <div className="flex flex-col gap-4 border-b p-6">
              <h2 className="text-2xl">Básico</h2>
              <p className="text-base">
                Mejora a nuestro <b className="font-matter-bold">Plan Básico</b> para obtener más flexibilidad y funciones mejoradas.
              </p>
              <div className="flex items-center gap-2">
                <div className="font-mark-bold text-5xl text-pkst-rose">$17</div>
                <div className="text-sm text-pkst-black">/ mensualmente</div>
              </div>
            </div>
            <div className="py-8 px-6">
              <p className="font-matter-bold text-base mb-1">Funcionalidades incluidas:</p>
              <p className="text-base mb-1">Incluye todas las funciones del plan gratuito.</p>
              <ul className="flex flex-col gap-1 mb-6">
                <li>· Sin límite de transmisión</li>
                <li>· 1 participante y varios en espera</li>
                <li>· Agregar logo propio</li>
                <li>· 3 destinos incluyendo RTMP personalizado</li>
                <li>· Stream utilizando herramientas de terceros</li>
                <li>· 60 hs de grabación en la nube</li>
                <li>· 1 co-administrador</li>
              </ul>
              <Button text="Elegir Plan Básico" className="secondary" onClick={handleModal} />
            </div>
          </div>
          <div className="flex flex-col border-2 border-pkst-rose rounded-lg">
            <div className="bg-pkst-rose uppercase text-white text-sm text-center px-2 py-3">
              recomendado para empresas
            </div>
            <div className="flex flex-col gap-4 border-b p-6">
              <h2 className="text-2xl">Profesional</h2>
              <p className="text-base">
                Descubre la solución definitiva de streaming para empresas con nuestro <b className="font-matter-bold">Plan Profesional</b>.
              </p>
              <div className="flex items-center gap-2">
                <div className="font-mark-bold text-5xl text-pkst-rose">$95</div>
                <div className="text-sm text-pkst-black">/ mensualmente</div>
              </div>
            </div>
            <div className="flex flex-col py-8 px-6 h-full min-h-[382px]">
              <div className="grow">
                <p className="font-matter-bold text-base mb-1">Funcionalidades incluidas:</p>
                <p className="text-base mb-1">Incluye todas las funciones del plan básico.</p>
                <ul className="flex flex-col gap-1 mb-6">
                  <li>· Stream propio con chat</li>
                  <li>· Conectar cámara adicional</li>
                  <li>· Hasta 6 destinos</li>
                  <li>· 4 co-administradores</li>
                </ul>
              </div>
              <div className="">
                <Button text="Elegir Plan Profesional" className="!w-full" onClick={handleModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}