import Divider from 'components/Divider';
import section from 'assets/images/section-recording.png';

export default function SectionRecording() {
  return (
    <section className="bg-pkst-gray">
      <div className="content">
        <div className="flex flex-col items-end">
          <img src={section} alt="" className="w-full lg:w-[350px] xl:w-full" />
        </div>
        <div className="flex flex-col justify-center w-full lg:w-[350px] xl:w-full">
          <div className="w-full lg:w-[350px] xl:w-full">
            <h2>Grabación y almacenamiento</h2>
            <Divider />
            <p>Graba y almacena tu video en nuestra plataforma para descargarlo y usarlo cuando quieras</p>
          </div>
        </div>
      </div>
    </section>
  );
}