import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import Home from 'routes/home';
import Prices from 'routes/prices';
import Layout from 'components/Layout';

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/prices',
        element: <Prices />
      },
    ]
  }
]);

export default function App() {
  return (
    <RouterProvider router={router} />
  );
}
