import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeContext } from 'configs/context';
import Nav from 'components/Nav';
import Footer from 'components/Footer';
import ModalUpdates from 'components/ModalUpdates';

export default function Layout() {
  const [state, setState] = useState({ modal: false });

  const updateState = (newValue) => {
    setState(newValue);
  }

  return (
    <>
      <ThemeContext.Provider value={{ state, updateState }}>
        <div className="flex flex-col h-screen">
          <Nav />
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
        <ModalUpdates />
      </ThemeContext.Provider>
    </>
  );
}