import 'assets/styles/hamburguer.css'

export default function Hamburguer({ active }) {
  return (
    <div className={`btn-hambuguer ${active ? 'active' : 'not-active'}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}