import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ThemeContext } from 'configs/context';
import Modal from 'components/Modal';
import Button from 'components/Button';
import iconMegaphone from 'assets/images/icon-modal.svg';
import iconClose from 'assets/images/icon-close.svg';
import iconError from 'assets/images/icon-error.svg';

export default function ModalUpdates() {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state, updateState } = useContext(ThemeContext);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors } } = useForm();

  const onSubmit = data => {
    setLoading(true);

    const url = 'https://api.sheety.co/8c9af8d57d34e6b39b6b4bcd20954cb0/peekast/newsletter';
    const body = {
      newsletter: {
        email: data.email
      }
    }

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then(() => {
        setSuccess(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleClose = () => {
    updateState({ modal: false });
  }

  useEffect(() => {
    reset();
  }, [state]);

  return (
    <Modal show={state.modal}>
      <button type="button" className="absolute right-8" onClick={handleClose}>
        <img src={iconClose} alt="" />
      </button>
      <div className="flex flex-col gap-8 items-center">
        <div className="flex flex-col items-center gap-1">
          <img src={iconMegaphone} alt="" width={96} className="p-4" />
          <h2 className="text-2xl">
            {success
              ? '¡Gracias por dejarnos tu email!'
              : 'Próximamente'
            }
          </h2>
          <p className="text-base text-center">
            {success
              ? 'Pronto recibirás novedades de nuestra parte.'
              : 'Déjanos tu correo electrónico y sé el primero en enterarte cuando estemos disponibles en tu ubicación.'
            }
          </p>
        </div>
        {!success && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex flex-col gap-4">
              <div>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className={`border rounded-full px-4 py-2.5 w-full focus:outline-none ${errors.email ? 'border-pkst-rose' : 'border-pkst-black'}`}
                  {...register('email', { required: true })} />
                {errors.email &&
                  <div className="text-base text-pkst-rose flex items-center gap-1 px-2 mt-1">
                    <img src={iconError} alt="" width={16} height={16} />
                    {t('form.validation.email')}
                  </div>
                }
              </div>
              <Button type="submit" text="Avísame cuando esté disponible" className="!w-full" loading={loading} />
              <Button text="No, gracias" className="!bg-transparent !text-pkst-rose !w-full" onClick={handleClose} />
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}